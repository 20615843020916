@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap');
:root {
    --theme-color:#198855;
    --theme-light-color:#808080;
    --theme-dark-color :#141414;
    --theme-bg-color :#2A2A2E;
    --theme-font: "Outfit", sans-serif;
}
body {
    background-color: #141414;
    font-family: var(--theme-font);
    color: #ffffff;
    font-weight: 400;
}
.font-regular {
    font-weight: 400;
}
.font-medium {
    font-weight: 500;
}
.font-semiBold {
    font-weight: 600;
}
.font-bold {
    font-weight: 700;
}
.font-extraBold {
    font-weight: 800;
}
a {
    text-decoration: none;
}
.text-light {
    color: var(--theme-light-color)!important;
}
.bg-light {
    background-color: var(--theme-light-color)!important;;
}
.btn {
    border-radius: 10px;
}
.text-primary {
    color: var(--theme-color)!important;
}
.btn-primary:hover,
.btn-primary:focus{
    color: #fff;
    background-color: #026236;
    border-color: #026236;
}

.btn-outline-primary {
    color: var(--theme-color);
    border-color: var(--theme-color);
}
.btn-outline-primary:hover,
.btn-outline-primary:focus
{
    color: #ffffff;
    border-color: var(--theme-color);
    background-color: var(--theme-color);
}
.btn-primary {
    color: #fff;
    background-color: var(--theme-color);
    border-color: var(--theme-color);
}
.login-modal .modal-dialog {
    max-width: 600px;
}
.modal.show .modal-dialog .modal-content {
    border-radius: 50px;
}
.md-body {
    max-width: 511px;
    margin: auto;
}
select.form-control,
input.form-control {
    height: 60px;
}
.form-control {
    background: #141414;
    border: 1px solid #484848;
    border-radius: 10px;

    padding: 15px 20px;
    color: #ffffff!important;
}
.form-control:disabled, .form-control[readonly] {
    background-color: #141414;
    opacity: 1;
}
.lock-control {
    background-image: url("/public/assets/images/lock.svg");
    background-size: 15px;
    background-repeat: no-repeat;
    background-position: right 15px center;
    color: var(--theme-light-color)!important;
}
.form-control:focus {
    background: #141414;
    border-color: var(--theme-color);
    box-shadow: none;
}
.login-modal .form-control {
    background: transparent;
    border: 1px solid #D9D9D9;
    border-radius: 10px;
    height: 60px;
    padding: 15px 20px;
    color: var(--theme-dark-color)!important;
}
select {
    background-image: url('/public/assets/images/select-arrow.png') !important;
    background-repeat: no-repeat !important;
    background-position: right 15px center !important;
}
.modal-light h4 {
    color: var(--theme-dark-color);
}
.modal-light p {
    color: var(--theme-dark-color);
}
.occasions-card h4 {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
}
body .owl-carousel .owl-nav button.owl-prev,body .owl-carousel .owl-nav button.owl-next {
    margin: 0;
    width: 40px;
    height: 40px;
    background-color: #fff;
    color: var(--theme-dark-color);
    font-size: 32px;
    border-radius: 50%;
    line-height: 0;
}
body .owl-carousel .owl-nav button.owl-prev span, body .owl-carousel .owl-nav button.owl-next span {
    position: relative;
    top: -4px;
}
.owl-nav {
    display: flex;
    justify-content: center;
    gap: 15px;
}
.zakereen-name h4 {
    font-size: 16px;
    margin-top: 10px;
}
.restaurant-card {
    background-color: var(--theme-bg-color);
    border: 1px solid rgba(0,0,0,0.1);
    border-radius: 10px;
}

.restaurant-img {
    height: 210px;
    overflow: hidden;
    border-radius: 20px;
    margin-bottom: 10px;
}
.restaurant-img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.restaurant-card {
    padding: 10px;
}
.header-right-div .btn {
    width: 108px;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.account-profile {
    background-color: var(--theme-bg-color);
    padding: 20px;
    border-radius: 10px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
}
.profile-img {
    width: 100px;
    height: 100px;
    border: 5px solid #fff;
    border-radius: 50%;
    overflow: hidden;
}
.profile-img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.account-menu-link a {
    background-color: var(--theme-bg-color);
    border: 1px solid #64676E;
    padding: 15px;
    border-radius: 10px;
    color: #CDCDCD;
    display: flex;
    gap: 10px;
    margin-bottom: 20px;
}
.account-logout-link a {
    display: flex;
    gap: 10px;
    text-align: center;
    justify-content: center;
}
.account-menu-link .active {
    background-color: var(--theme-color);
    color: #ffffff;
}
.account-menu-link a:hover {
    background-color: var(--theme-color);
    color: #ffffff;
}
.dark-card {
    background-color: var(--theme-bg-color);
    padding: 20px;
    border-radius: 10px;
}
.form-label {
    color: var(--theme-light-color) !important;
}
.breadcrumb li a {
    color: var(--theme-light-color);
}
.front-footer {
    background-color: var(--theme-bg-color);
    padding: 70px 0 20px;
}
.footer-box h4 {
    font-size: 18px;
    margin-bottom: 15px;
}
.footer-box ul li {
    display: block;
}
.footer-box ul li a {
    font-size: 14px;
    color: var(--theme-light-color);
}
.footer-box p {
    font-size: 14px;
}
.footer-box a:hover {
    color: var(--theme-color);
}
.footer-social a {
    font-size: 30px;
    color: #F9F9F9;
    margin-right: 15px;
    display: block;
    line-height: 0;
}
.footer-social {
    display: flex;
}
.bottom-footer p {
    text-align: center;
    color: var(--theme-light-color);
    border-top: 1px solid var(--theme-light-color);
    padding-top: 15px;
    font-size: 14px;
}
.bottom-footer {
    margin-top: 20px;
}
.resto-cover-bottom {
    display: flex;
    gap: 20px;
}
.resto-profile {
    flex: 0 0 255px;
    max-width: 255px;
    border-radius: 15px;
    overflow: hidden;
    height: 255px;
}
.resto-profile img {
    width: 100%;
    object-fit: cover;
    height: 100%;
}
.resto-name-detail h4 {
    font-size: 33px;
    font-weight: 600;
    margin-bottom: 0;
}
.resto-name-detail p {
    font-size: 18px;
    color: var(--theme-light-color);
}
.resto-cover-bottom {
    display: flex;
    gap: 20px;
    margin-top: -110px;
    align-items: end;
    padding: 0 20px 0;
}
.resto-name-detail {
    margin-bottom: 25px;
}
.item-search-box .btn {
    position: absolute;
    right: 5px;
    top: 10px;
}
.item-search-box .form-control {
    height: 45px;
}
.item-search-box .btn {
    position: absolute;
    right: 5px;
    top: 0;
    padding: 8px 10px;
}
.item-search-box {
    max-width: 365px;
    margin-left: auto;
}
.resto-item-title {
    font-size: 33px;
    font-weight: 600;
    margin: 0;
}
.qty-group-box {
    display: flex;
    max-width: 135px;
}
.qty-group-box .btn {
    background-color: #ffffff;
    border-radius: 0;
    width: 42px;
    height: 42px;
    font-size: 20px;
    flex: 0 0 42px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.qty-group-box input {
    border: none;
    height: 100%;
    display: flex;
    align-items: center;
    text-align: center;
    padding: 4px 0;
    font-size: 22px;
}
/* Chrome, Safari, Edge, Opera */
.qty-group-box input::-webkit-outer-spin-button,
.qty-group-box input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
.qty-group-box input[type=number] {
    -moz-appearance: textfield;
}
.add-to-cart-btn {
    max-width: 240px;
    width: 100%;
    font-size: 18px;
    font-weight: 600;
    padding: 10px 0;
    height: 60px;
    margin-top: 20px;
}
.resto-menu-card {
    display: flex;
    gap: 25px;
}
.resto-img-div {
    flex: 0 0 300px;
    height: 260px;
    border: 1px solid #383838;
    max-width: 310px;
    border-radius: 5px;
    overflow: hidden;
}
.resto-img-div img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.resto-menu-card {
    display: flex;
    gap: 25px;
    border-bottom: 1px solid #4f4f4f;
    padding-bottom: 25px;
    margin-bottom: 25px;
}
.resto-item-detail h4 {
    font-size: 30px;
    font-weight: 600;
}
.resto-item-detail h5 {
    font-size: 24px;
    font-weight: 600;
}
.resto-item-detail p {
    font-size: 16px;
}
.checkout-menu .resto-menu-card {
    border: 1px solid #ffffff;
    padding: 20px;
    border-radius: 10px;
}
.item-delete-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 24px;
}
.checkout-menu .resto-img-div {
    flex: 0 0 200px;
    height: 200px;
}
.checkout-right {
    border: 1px solid #fff;
    padding: 20px;
    border-radius: 10px;
    background-color: #000000;
}
.checkout-value p {
    color: var(--theme-light-color);
}
.checkout-value h5 {
    font-weight: 600;
}
.login-modal .btn-close {
    position: absolute;
    right: 20px;
    top: 20px;
}
.owl-theme .owl-dots .owl-dot.active span, .owl-theme .owl-dots .owl-dot:hover span {
    background: var(--theme-color)!important;
}
.sect-view-all:hover {
    color: var(--theme-color)!important;
}
.header-right-div .nav-item.dropdown a.dropdown-toggle {
    padding: 10px 0;
}
.navbar-header-menu .navbar-nav .nav-item {
    margin: 0 15px;
}
.navbar-header-menu .navbar-nav .nav-item a:hover{
     color: var(--theme-color);
 }
.language-dropdown ul li a img {
    width: 40px;
}
.language-dropdown ul li a {
    display: flex;
    gap: 15px;
    align-items: center;
}
.language-dropdown ul li a:hover {
    color: var(--theme-color);
}
.language-dropdown .dropdown-menu {
    padding: 0;
}
.language-dropdown .dropdown-menu li a {
    padding: 12px 15px;
}
.res-header {
    display: flex;
    align-items: center;
    gap: 20px;
}
.res-img-div {
    width: 128px;
    height: 128px;
    border-radius: 50%;
    overflow: hidden;
}
.res-img-div img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.res-name-div p {
    color: var(--theme-light-color);
}
.res-name-div h2 {
    font-size: 24px;
}
.res-header {
    padding: 20px;
    border-bottom: 1px solid #64676E;
}
.res-dark-footer {
    padding: 20px;
    border-top: 1px solid #64676E;
}
.res-dark-card {
    background-color: var(--theme-bg-color);
    border-radius: 15px;
}
.res-dark-body {
    padding: 20px;
}
.res-card-group label {
    color: var(--theme-light-color);
    text-transform: capitalize;
    font-size: 16px;
    margin-bottom: 5px;
}
.res-card-group h6 {
    font-size: 18px;
}
.res-card-group {
    margin-bottom: 20px;
}
.res-dark-footer .btn {
    min-width: 127px;
    height: 51px;
    font-weight: 600;
}
.res-dark-footer {
    display: flex;
    gap: 10px;
    justify-content: end;
}
.resto-cover-img {
    border-radius: 20px;
    height: 400px;
    overflow: hidden;

}
.resto-cover-img img{
    width: 100%;
}
.package-card {
    background-color: var(--theme-bg-color);
    padding: 15px;
    border-radius: 15px;
    text-align: center;
}
.package-card .btn {
    padding: 10px 15px;
    display: block;
    font-weight: 600;
    font-size: 15px;
    height: 51px;
}
.package-card h5 {
    font-size: 24px;
}
.package-body {
    padding: 20px;
}
.profile-change-box {
    position: relative;
}
.image-changes-div input {
    display: none;
}
.image-changes-div {
    position: absolute;
    right: 0;
    bottom: 0;
}
.image-changes-div label {
    font-size: 12px;
    width: 32px;
    height: 32px;
    background-color: var(--theme-color);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #ffffff;
}
.restaurant-cover-img {
    border-radius: 15px;
    overflow: hidden;
    margin-bottom: 20px;
}
.restaurant-cover-btn-div {
    position: absolute;
    bottom: 20px;
    right: 20px;
}
.res-package-card {
    padding: 15px;
    margin-bottom: 30px;
}
.res-pac-img img {
    width: 100%;
    border-radius: 15px;
    height: 100%;
    object-fit: cover;
}
.res-pac-img {
    height: 210px;
    overflow: hidden;
}
.res-pac-detail h3 {
    font-size: 24px;
}
.res-pac-detail h4 {
    font-size: 20px;
}
.occasions-card {
    background-color: var(--theme-bg-color);
    height: 270px;
    border-radius: 15px;
    overflow: hidden;
}
.occasions-card img {
    height: 100%;
    width: 100%;
    object-fit: cover;
}
.zakereen-img {
    height: 175px;
    overflow: hidden;
    border-radius: 15px;
}
.zakereen-img img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.form-icon-group {
    position: relative;
}
.form-icon-group span {
    position: absolute;
    top: 15px;
    right: 20px;
    background-color: #ffffff;
}
.form-icon-group .form-control {
    -webkit-appearance: none;
}
.form-icon-group input[type=date]::-webkit-calendar-picker-indicator {
    visibility: hidden;
}
.event-step-ul {
    display: flex;
    text-align: center;
    justify-content: space-evenly;
    padding: 0;
}
.event-step-ul li {
    flex: 0 0 50%;
    display: block;
    max-width: 100%;
    position: relative;
}
.event-step-ul li span {
    width: 40px;
    height: 40px;
    background-color: transparent;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto 10px;
    border: 1px solid var(--theme-light-color);
    background-color: var(--theme-dark-color);
    color: var(--theme-light-color);
    font-size: 18px;
}
.event-step-ul li.active span {
    background-color: var(--theme-color);
    border-color: var(--theme-color);
    color: #ffffff;
}
.event-step-ul li::after {
    content: "";
    width: 50%;
    height: 2px;
    background-color: var(--theme-light-color);
    position: absolute;
    right: 0;
    top: 19px;
    z-index: -1;
}
.event-step-ul li.active::after {
    background-color: var(--theme-color);
}
.event-step-ul li.active:last-child::after {
    background-color: var(--theme-color);
}
.event-step-ul li:last-child::after {
    content: "";
    width: 50%;
    height: 2px;
    background-color: var(--theme-light-color);
    position: absolute;
    right: auto;
    left: 0;
    top: 19px;
    z-index: -1;
}
.zakiri-list .zakereen-card .zakereen-img {
    height: 270px;
}
.zakiri-list .zakereen-card .zakereen-name h4 {
    font-size: 24px;
    margin-top: 10px;
}

.page-item.disabled .page-link {
    color: #6c757d;
    pointer-events: none;
    background-color: #2a2a2e;
    border-color: transparent;
}
.page-link {
    position: relative;
    display: block;
    color: #ffffff;
    text-decoration: none;
    background-color: var(--theme-bg-color);
    border: 1px solid #2a2a2e;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    padding: 10px 20px;
    margin: 0 5px;
    border-radius: 5px;
}
.page-item.active .page-link {
    z-index: 3;
    color: #fff;
    background-color: var(--theme-color);
    border-color: var(--theme-color);
}
.page-link:hover {
    z-index: 2;
    color: #ffffff;
    background-color: var(--theme-color);
    border-color: var(--theme-color);
}
.package-pac-img {
    background-color: var(--theme-dark-color);
    border-radius: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.audio-box {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 15px;
}
.video-card {
    border-radius: 15px;
    overflow: hidden;
}
.pl-btn {
    position: absolute;
    top: 0;
    bottom: 0;
    height: 50px;
    background-color: #ffffff;
    width: 50px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--theme-color);
    font-size: 22px;
    right: 0;
    left: 0;
    margin: auto;
}
.video-card:hover .pl-btn{
    background-color: var(--theme-color);
    color: #ffffff;
}
.preloder {
    height: 100vh;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #141414;
    z-index: 9999999;
    /* display: block; */
    /* display: flex; */
}
.loader-logo {
    position: absolute;
    left: 0;
    right: 0;
    width: 40px;
    top: 0;
    bottom: 0;
    height: 55px;
    margin: auto;
}

.loder{
    width: 100px;
    height: 100px;
    border-radius: 50%;
    border: 5px solid #D1DFFF;
    border-top: 8px solid var(--theme-color);
    animation: spinner 1s linear infinite;
}
.event-list-div {
    .event-card {
        background-color: #2a2a2e;
        padding: 20px;
        border-radius: 15px;
        display: flex;
        gap: 25px;
        align-items: center;
        .ec-date {
            flex: 0 0 100px;
            height: 100px;
            border-radius: 15px;
            background-color: #198855;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            width: 100%;
            max-width: 100px;
            svg {
                width: 50px;
                height: 50px;
                margin-bottom: 5px;
                path {
                    fill: #ffffff;
                }
            }
        }
        .view-btn {
            flex : 0 0 100px;
            .btn {
                color: var(--theme-color);
                font-size: 16px;
                text-decoration: underline;
            }
        }
    }
}
.evnet-icon {
    width: 100px;
    height: 100px;
    background-color: var(--theme-color);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    margin: auto;
    svg {
        width: 40px;
        height: 40px;
        path {
            fill: #ffffff;

        }
    }
}
@keyframes spinner{
    0%{
        transform: rotate(0deg);

    }
    50%{
        border-top-width: 5px;
    }
    100%{
        transform: rotate(360deg);
    }
}

.selectcheckbox {
    position: relative;
}
.selectcheckbox label {
    font-size: 16px;
    font-weight: 500;
    position: relative;
    padding-left: 35px;
}
.selectcheckbox {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 15px;
}
.selectcheckbox input {
    height: 24px;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    z-index: 1;
    cursor: pointer;
    opacity: 0;
}
.selectcheckbox label::before {
    content: "";
    width: 24px;
    height: 24px;
    background-color: #ffffff;
    display: block;
    border-radius: 50%;
    position: absolute;
    top: 0;
    left: 0;
}
.selectcheckbox label::after {
    content: "";
    width: 14px;
    height: 14px;

    display: block;
    border-radius: 50%;
    position: absolute;
    top: 5px;
    left: 5px;
}
.selectcheckbox input:checked + label::after {
    background-color: var(--theme-color);
}
.page-content-div p {
    line-height: 34px;
    font-size: 16px;
    font-weight: 400;
    opacity: 0.9;
}
.cb-head span {
    width: 55px;
    height: 55px;
    background-color: #198855;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.cb-head {
    display: flex;
    align-items: center;
    gap: 15px;
    margin-bottom: 20px;
}
.cb-head h6 {
    font-size: 20px;
    margin: 0;
}
.cb-body p {
    margin: 0 0 10px 0;
    font-size: 18px;
}
.contact-box {
    border: 1px solid rgba(255,255,255,.55);
    margin-bottom: 30px;
    padding: 40px;
    border-radius: 15px;
}
.cart-div {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 45px;
    height: 45px;
    border-radius: 10px;
    position: relative;
}
.cart-div span {
    position: absolute;
    top: -10px;
    right: -5px;
    background-color: var(--theme-color);
    font-size: 12px;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ffffff;
}
@media screen and (max-width: 668px){
    .loder{
        height: 60px;
        width: 60px;
        border-top: 6px solid #3775FF;
    }
}
@media screen and (max-width: 1199px) {
    .restaurant-img {
        height: 170px;
    }
    .zakiri-list .zakereen-card .zakereen-img {
        height: 170px;
    }
    .zakiri-list .zakereen-card .zakereen-name h4 {
        font-size: 16px;
    }
}

@media screen and (max-width: 1099px) {
    .res-card-group label {
        font-size: 14px;
    }
    .res-card-group h6 {
        font-size: 16px;
    }
    .res-name-div h2 {
        font-size: 20px;
    }
    .res-img-div {
        width: 90px;
        height: 90px;
    }
    .restaurant-img {
        height: 150px;
    }

}
@media screen and (max-width: 767px) {
    body {
        font-size: 14px;
    }
    .resto-item-title {
        font-size: 24px;
    }
    .resto-profile {
        flex: 0 0 105px;
        max-width: 105px;
        height: 105px;
        border-radius: 5px;
    }
    .resto-cover-bottom {
        padding: 0 10px;
        margin-top: -30px;
    }
    .resto-name-detail {
        margin-bottom: 0;
    }
    .resto-name-detail h4 {
        font-size: 18px;
    }
    .resto-name-detail p {
        font-size: 14px;
    }
    .resto-item-detail h4 {
        font-size: 18px;
    }
    .resto-item-detail h5 {
        font-size: 16px;
        font-weight: 600;
    }
    .resto-item-detail p {
        font-size: 14px;
    }

    .resto-img-div {
        flex: 0 0 180px;
        height: 180px;
        max-width: 180px;
    }
    .add-to-cart-btn {
        max-width: 200px;
        font-size: 14px;
        height: 50px;
    }
    .sect-title-div h3 {
        font-size: 20px;
    }
    .restaurant-dtl h5 {
        font-size: 16px;
    }
    header .navbar-brand img {
        height: 60px;
    }
    header .container-fluid {
        padding: 0;
    }
    .footer-box {
        margin-bottom: 15px;
    }
    .front-footer {
        padding: 40px 0 20px;
    }
    .footer-logo-box {
        text-align: center;
    }
    .footer-logo-box a {
        margin-bottom: 16px;
        display: block;
    }
    .hrd-main {
        flex-direction: column;
    }
    .res-pac-detail h3 {
        font-size: 22px;
    }
    .res-pac-detail h4 {
        font-size: 18px;
    }
    .restaurant-img {
        height: 130px;
    }
    .resto-cover-img {
        height: 120px;
    }
    .resto-cover-img {
        border-radius: 10px;
    }

    .event-list-div {
        .event-card {

            gap: 15px;
            flex-direction: column;
            text-align: center;

            .view-btn {
                flex : 0 0 auto;

            }
        }
    }

}
@media screen and (max-width: 575px) {
    .resto-menu-card {
        flex-wrap: wrap;
    }
    .resto-img-div {
        flex: 0 0 100%;
        max-width: 100%;
        height: 270px;
    }
    .add-to-cart-btn {
        max-width: 100%;
        width: 100%;
        font-size: 14px;
        height: 50px;
    }

}